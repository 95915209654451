<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.profiles") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="roles"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="6" xl="4" cols="6">
              <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.titles.filter')"
                :clearable="true"
                single-line
                hide-details
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col lg="4" md="4" sm="4" xl="4" cols="4" class="text-end">
              <v-btn
                small
                tile
                :elevation="0"
                color="gray"
                style="border-radius: 1.5em;"
                v-if="check([{ domain: 'Role', permisions: ['Write'] }])"
                @click="showAdd"
              >
                <v-icon small color="neuter" left>mdi-plus</v-icon>
                {{ $t("general.buttons.new") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="
                item.canDeleted &&
                  check([{ domain: 'Role', permisions: ['Update'] }])
              "
              color="neuter"
              class="mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.profiles") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="
                item.canDeleted &&
                  check([{ domain: 'Role', permisions: ['Delete'] }])
              "
              color="neuter"
              small
              @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.profiles") }}
            </span>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="900px">
        <v-card color="white">
          <v-card-title class="headline pa-6">{{ title }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-6">
            <v-container>
              <v-form ref="form1" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceName" v-on:keyup.ctrl.86="replaceName"
                                  color="primary"
                                  v-model="editedItem.name"
                                  required
                                  class="required"
                                  :rules="requireAndMaxRules(max)"
                                  :counter="max"
                                  outlined
                                  dense
                    >
                      <template v-slot:label>
                        {{$t('roles.fields.name')}} <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      cols="6"
                      v-for="(editedModule,i) in order(
                          editedItem.permissionByDomainDTOList
                        )"
                      v-if="
                          editedItem.permissionByDomainDTOList.length &&
                            editedModule.domain
                        "
                      :key="i"
                  >
                    <template v-for="module in order(modules)">
                      <v-select
                        outlined
                        dense
                          v-if="module.domain === editedModule.domain"
                          color="primary"
                          item-color="primary"
                          deletable-chips
                          v-model="editedModule.permissions"
                          :label="$t(`modules.name.${module.domain}`)"
                          :key="'select' + editedModule.module"
                          :items="orderDatas(module.permissions)"
                          small-chips
                          multiple
                      >
                      </v-select>
                    </template>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="close">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn
                small elevation="0" color="primary" class="t-bw-primary--text"
                :disabled="!valid"
                @click="save"
            >
              {{ $t("general.buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import LanguajeSelect from "@/components/LanguajeSelect";
import _ from "lodash";
import PermisionService from "../../../services/PermisionService";
import LanguajeService from "../../../services/LanguajeService";
import sortList from '@/services/OrderListService';

export default {
  name: "Roles",
  components: { LanguajeSelect },
  data: () => ({
    langs: [],
    editedIndex: -1,
    search: "",
    editedItem: {
      name: "",
      permissionByDomainDTOList: [],
    },
    list: [],
    dialog: false,
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    valid: false,
    max: 40,
    deletingItem: {},
  }),

  computed: {
    ...mapGetters({
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      roles: "roles/roles",
      modules: "roles/modules",
      languageStructure: "general/languageStructure",
    }),
    headers: () => [
      { text: i18n.t("roles.fields.name"), value: "name" },
      {
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 100,
        sortable: false,
        align: "center",
      },
    ],
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("general.titles.new")
          : i18n.t("general.titles.edit")) +
        " " +
        i18n.t("roles.titles.new_role")
      );
    },
  },

  async created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      fetchListRoles: "roles/fetchListRoles",
      fetchListModules: "roles/fetchListModules",
      saveRoles: "roles/saveRoles",
      deleteRol: "roles/deleteRol",
    }),
    orderDatas( list ){
        return sortList.orderListByUppercaseRole( list );
    },
    initialize() {
      this.langs = this.$i18n.availableLocales;
      this.profile = JSON.parse(localStorage.getItem("profile"));
      this.fetchListRoles([this.profile.company_id, this.$toast]);
      this.fetchListModules([this.$toast]);
    },

    async deleteItem() {
      this.dialogClose();
      this.deleteRol([this.deletingItem.id, this.$toast]).finally(() => {
        this.fetchListRoles([this.profile.company_id, this.$toast]);
      });
    },

    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },

    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("general.titles.alert"),
        dialogText: i18n.t("general.notification.deleteItems"),
      });
    },
    order(items) {
      return _.orderBy(items, (item) => {
        return i18n.t(`modules.name.${item.domain}`);
      });
    },
    check(permisions) {
      return PermisionService.check(permisions);
    },
    dialogClose() {
      this.showDialog = false;
    },

    close() {
      setTimeout(() => {
        this.editedItem = {
          id: null,
          canDeleted: false,
          name: "",
          permissionByDomainDTOList: [],
        };
        this.editedIndex = -1;
      }, 300);
      this.dialog = false;
    },

    save() {
      let item = Object.assign({}, this.editedItem);
      // Translate
      item.language_key = LanguajeService.setKey3(
        item.name,
        item.language_key,
        !item.id ? this.languageStructure : null
      );
      // *****

      item.company_id = this.profile.company_id;
      this.close();
      this.saveRoles([item, this.editedIndex, this.$toast]).finally(() => {
        this.fetchListRoles([item.company_id, this.$toast]);
      });
    },
    editItem(item) {
      this.editedIndex = this.roles.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.setModelPermissionByDomain(
        this.editedItem.permissionByDomainDTOList
      );
      this.dialog = true;
    },
    setModelPermissionByDomain(userPermisionList) {
      let temp = _.cloneDeep(this.modules);
      temp.forEach((module) => {
        let userPermission = userPermisionList
          ? _.find(userPermisionList, { module: module.domain })
          : null;
        module.permissions = userPermission ? userPermission.permission : [];
      });
      this.editedItem.permissionByDomainDTOList = temp;
    },
    showAdd() {
      this.setModelPermissionByDomain();
      this.dialog = true;
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceName(event){
      this.editedItem.name =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
};
</script>

<style scoped></style>
